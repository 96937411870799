


































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "other"
})
export default class extends Vue {
  arry: any[] = [
    {
      img: require("@/assets/other/icon1.png"),
      text: "互联网金融借贷",
      href: "https://jubao.nifa.org.cn/ipnifa"
    },
    {
      img: require("@/assets/other/icon2.png"),
      text: "网络违法犯罪行为",
      href: "http://www.cyberpolice.cn/wfjb"
    },
    {
      img: require("@/assets/other/icon3.png"),
      text: "网络交易纠纷",
      href: "http://www.12315.cn"
    },
    {
      img: require("@/assets/other/icon4.png"),
      text: "网络虚假违法广告",
      href: "http://www.12315.cn"
    },
    {
      img: require("@/assets/other/icon5.png"),
      text: "网络敲诈删帖",
      href: "http://cyberpolice.mps.gov.cn/wfjb"
    },
    {
      img: require("@/assets/other/icon6.png"),
      text: "邮件、电话骚扰",
      href: "https://www.12321.cn"
    },
    {
      img: require("@/assets/other/icon7.png"),
      text: "知识产权侵权",
      href: "http://www.12315.cn"
    },
    {
      img: require("@/assets/other/icon8.png"),
      text: "盗版侵权",
      href: "http://www.shdf.gov.cn/shdf/channels/740.html"
    },
    {
      img: require("@/assets/other/icon9.png"),
      text: "假媒体、假记者",
      href: "http://www.shdf.gov.cn/shdf/channels/740.html"
    },
    {
      img: require("@/assets/other/icon10.png"),
      text: "涉黑涉恶违法犯罪",
      href: "http://www.12337.gov.cn/"
    },
    {
      img: require("@/assets/other/icon11.png"),
      text: "网络音乐、网络表演、网络动漫等网络文化市场经营活动",
      href: "http://jbts.mct.gov.cn"
    },
    {
      img: require("@/assets/other/icon12.png"),
      text: "电信用户服务",
      href: "https://yhssglxt.miit.gov.cn/web"
    }
  ];
  href(link: string) {
    window.open(link);
  }
}
